import { gql } from '@/__generated__';

export const AUTHOR = gql(`
  fragment author on UserInterface {
      __typename
      id
      displayName: fullName
      firstName
      lastName
  }
`);

export const DOCUMENT_BASE_FRAGMENT = gql(`
  fragment documentBase on AssetInterface {
    mimeType
    id
    alt
    title
  }
`);

export const DOCUMENT_FRAGMENT = gql(`
  fragment document on AssetInterface {
    ...documentBase
    __typename
    url
    filename
    extension
    size
  }
`);
