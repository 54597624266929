import { gql } from '@/__generated__';

export const IMAGE_SIZE_ORIGINAL_FRAGMENT = gql(`
  fragment imageSize_original on AssetInterface {
    original_url: url
    original_height: width
    original_width: height
  }
`);

export const IMAGE_SIZE_BANNER_CROP_FRAGMENT = gql(`
  fragment imageSize_bannerCrop on AssetInterface {
    bannerCrop_url: url @transform(transform: "bannerCrop")
    bannerCrop_width: width @transform(transform: "bannerCrop")
    bannerCrop_height: height @transform(transform: "bannerCrop")
  }
`);

export const IMAGE_SIZE_FULL_FIT_FRAGMENT = gql(`
  fragment imageSize_fullFit on AssetInterface {
    fullFit_url: url @transform(transform: "fullFit")
    fullFit_width: width @transform(transform: "fullFit")
    fullFit_height: height @transform(transform: "fullFit")
  }
`);

export const IMAGE_SIZE_LANDSCAPE_SMALL_CROP_FRAGMENT = gql(`
  fragment imageSize_landscapeSmCrop on AssetInterface {
    landscapeSmCrop_url: url @transform(transform: "landscapeSmCrop")
    landscapeSmCrop_width: width @transform(transform: "landscapeSmCrop")
    landscapeSmCrop_height: height @transform(transform: "landscapeSmCrop")
  }
`);

export const IMAGE_SIZE_LANDSCAPE_MEDIUM_CROP_FRAGMENT = gql(`
  fragment imageSize_landscapeMdCrop on AssetInterface {
    landscapeMdCrop_url: url @transform(transform: "landscapeMdCrop")
    landscapeMdCrop_width: width @transform(transform: "landscapeMdCrop")
    landscapeMdCrop_height: height @transform(transform: "landscapeMdCrop")
  }
`);

export const IMAGE_SIZE_LANDSCAPE_LARGE_CROP_FRAGMENT = gql(`
  fragment imageSize_landscapeLgCrop on AssetInterface {
    landscapeLgCrop_url: url @transform(transform: "landscapeLgCrop")
    landscapeLgCrop_width: width @transform(transform: "landscapeLgCrop")
    landscapeLgCrop_height: height @transform(transform: "landscapeLgCrop")
  }
`);

export const IMAGE_SIZE_SQUARE_SMALL_CROP_FRAGMENT = gql(`
  fragment imageSize_squareSmCrop on AssetInterface {
    squareSmCrop_url: url @transform(transform: "squareSmCrop")
    squareSmCrop_width: width @transform(transform: "squareSmCrop")
    squareSmCrop_height: height @transform(transform: "squareSmCrop")
  }
`);

export const IMAGE_SIZE_SQUARE_MEDIUM_CROP_FRAGMENT = gql(`
  fragment imageSize_squareMdCrop on AssetInterface {
    squareMdCrop_url: url @transform(transform: "squareMdCrop")
    squareMdCrop_width: width @transform(transform: "squareMdCrop")
    squareMdCrop_height: height @transform(transform: "squareMdCrop")
  }
`);

export const IMAGE_SIZE_SQUARE_LARGE_CROP_FRAGMENT = gql(`
  fragment imageSize_squareLgCrop on AssetInterface {
    squareLgCrop_url: url @transform(transform: "squareLgCrop")
    squareLgCrop_width: width @transform(transform: "squareLgCrop")
    squareLgCrop_height: height @transform(transform: "squareLgCrop")
  }
`);

export const IMAGE_SIZE_ALL_FRAGMENT = gql(`
  fragment imageSize_all on AssetInterface {
    ...imageSize_original
    ...imageSize_bannerCrop
    ...imageSize_fullFit
    ...imageSize_landscapeLgCrop
    ...imageSize_landscapeMdCrop
    ...imageSize_landscapeSmCrop
    ...imageSize_squareLgCrop
    ...imageSize_squareMdCrop
    ...imageSize_squareSmCrop
  }
`);

export const IMAGE_SIZE_COMMON_FRAGMENT = gql(`
  fragment imageSize_common on AssetInterface {
    ...imageSize_original
    ...imageSize_bannerCrop
    ...imageSize_fullFit
    ...imageSize_landscapeLgCrop
    ...imageSize_landscapeMdCrop
    ...imageSize_landscapeSmCrop
    ...imageSize_squareLgCrop
    ...imageSize_squareMdCrop
    ...imageSize_squareSmCrop
  }
`);

export const IMAGE_BASE_FRAGMENT = gql(`
  fragment imageBase on AssetInterface {
    mimeType
    id
    alt
    title
    url @transform(transform: "fullFit")
    height @transform(transform: "fullFit")
    width @transform(transform: "fullFit")
  }
`);

export const IMAGE_FRAGMENT = gql(`
  fragment image on AssetInterface {
    ...imageBase
    ...imageSize_common
  }
`);
