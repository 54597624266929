import { gql } from '@/__generated__';

export const PARTNER_GLOBAL_FRAGMENT = gql(`
    fragment partnersGlobal on partners_GlobalSet {
        __typename
        id
        enabled
        partnerMultiple {
            ...partnerMultiple
        }
    }
`);

export const LINKEDIN_URL_GLOBAL_FRAGMENT = gql(`
    fragment linkedinUrlGlobal on linkedinUrl_GlobalSet {
        __typename
        id
        enabled
        websiteUrl
    }
`);

export const GLOBALS_FRAGMENT = gql(`
    fragment globals on GlobalSetInterface {
        ...partnersGlobal
        ...linkedinUrlGlobal
    }
`);
