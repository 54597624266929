'use client';

import React from 'react';
import { NavMenuItem } from '@/lib/parse/navigation/navigation';
import Flex from '@/polymorphics/Flex';
import Btn from '@/polymorphics/Btn';
import Txt from '@/polymorphics/Txt';
import Box from '@/polymorphics/Box';
import { useBreakpointMax } from '@/style/utils/useBreakpoints';

export type FooterEntries = {
  items: NavMenuItem[] | undefined;
};

export default function FooterEntries({ items }: FooterEntries) {
  const md = useBreakpointMax('md');

  return (
    <Flex cx={{ width: 'full', rowGap: '2' }} direction={md ? 'column' : undefined}>
      {items?.map((item, idx) => {
        return (
          <Box
            key={item.id}
            cx={{
              mR: items.length - 1 === idx ? 'none' : 'auto',
              decoration: 'none',
            }}>
            <Flex direction="column" cx={{ rowGap: '2' }}>
              <Btn
                variant="link"
                type="link"
                href={item.href ?? ''}
                decoration="hover"
                cx={{
                  decoration: 'none',
                }}>
                <Txt as="span" variant="md" bold>
                  {item.title}
                </Txt>
              </Btn>
              {item.subItems?.map((subItem) => {
                return (
                  <Btn
                    key={subItem.id}
                    variant="link"
                    type="link"
                    href={subItem.href ?? ''}
                    decoration="hover"
                    cx={{
                      decoration: 'none',
                    }}>
                    <Txt as="span" variant="md">
                      {subItem.title}
                    </Txt>
                  </Btn>
                );
              })}
            </Flex>
          </Box>
        );
      })}
    </Flex>
  );
}
