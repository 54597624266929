import { gql } from '@/__generated__';

export const NAVIGATION_BASE_FRAGMENT = gql(`
  fragment navigationBase on NodeInterface {
    __typename
    id
    uri
    url
    level
    title
    newWindow
    typeLabel
    classes
    parent {
      id
    }
    element {
        uri
        language
    } 
  }
`);
