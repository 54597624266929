import { create } from 'zustand';

type SubItemToggle = {
  show: boolean;
  toggleShow: () => void;
  hide: () => void;
};

export const useSubItem = create<SubItemToggle>((set) => ({
  show: false,
  toggleShow: () => set((state) => ({ show: !state.show })),
  hide: () => set(() => ({ show: false })),
}));
