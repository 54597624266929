import { SVG_SIZE, SVGType } from '@/lib/constants';
import React from 'react';

interface Close extends SVGType, React.HTMLAttributes<SVGElement> {
  stroke?: string;
}

export default function Close({ className, stroke, size }: Close) {
  return (
    <svg
      className={className}
      style={{
        height: SVG_SIZE[size].height,
        width: SVG_SIZE[size].width,
        transition: '200ms ease all',
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32">
      <path
        fill="currentColor"
        stroke={stroke}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00023 5.87866L16.0002 13.8787L24.0002 5.87866L26.1215 7.99998L18.1215 16L26.1215 24L24.0002 26.1213L16.0002 18.1213L8.00023 26.1213L5.87891 24L13.8789 16L5.87891 7.99998L8.00023 5.87866Z"
      />
    </svg>
  );
}
