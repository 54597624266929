import { SVG_SIZE, SVGType } from '@/lib/constants';
import React from 'react';

interface Menu extends SVGType, React.HTMLAttributes<SVGElement> {
  stroke?: string;
}

export default function Menu({ className, stroke, size }: Menu) {
  return (
    <svg
      className={className}
      style={{
        height: SVG_SIZE[size].height,
        width: SVG_SIZE[size].width,
        transition: '200ms ease all',
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32">
      <path
        stroke={stroke}
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.8335 6.5H28.1668V9.5H3.8335V6.5ZM3.8335 14.5H28.1668V17.5H3.8335V14.5ZM3.8335 22.5H28.1668V25.5H3.8335V22.5Z"
      />
    </svg>
  );
}
