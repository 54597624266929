import { createFragmentRegistry } from '@apollo/client/cache';
import * as COMMON_FRAGMENTS from '@/gql/fragments/common.gql';
import * as LINK_FRAGMENTS from '@/gql/fragments/linkField.gql';
import * as ENTRY_FRAGMENTS from '@/gql/fragments/entries.gql';
import * as ENTRY_CARDS_FRAGMENTS from '@/gql/fragments/entryCards.gql';
import * as BLOCK_FRAGMENTS from '@/gql/fragments/blocks.gql';
import * as IMAGE_FRAGMENTS from '@/gql/fragments/images.gql';
import * as GLOBAL_FRAGMENTS from '@/gql/fragments/globals.gql';
import * as NAVIGATION_FRAGMENTS from '@/gql/fragments/navigation.gql';

const typedFragments = [
  ...Object.values(COMMON_FRAGMENTS),
  ...Object.values(LINK_FRAGMENTS),
  ...Object.values(ENTRY_FRAGMENTS),
  ...Object.values(ENTRY_CARDS_FRAGMENTS),
  ...Object.values(BLOCK_FRAGMENTS),
  ...Object.values(IMAGE_FRAGMENTS),
  ...Object.values(GLOBAL_FRAGMENTS),
  ...Object.values(NAVIGATION_FRAGMENTS),
];

export const fragmentRegistry = createFragmentRegistry(...typedFragments);
