'use client';

import Box from '@/polymorphics/Box';
import Btn from '@/polymorphics/Btn';
import Flex from '@/polymorphics/Flex';
import Txt from '@/polymorphics/Txt';
import { Drawer } from '@mui/material';
import Close from '../Assets/Close';
import Menu from '../Assets/Menu';
import { NavBar } from '../NavBar';
import NavDrawerItem from '../NavDrawerItem';
import * as styles from './NavDrawer.css';
import { useNavDrawer } from './useNavDrawer';

export default function NavDrawer(props: NavBar) {
  const { value: menuOpen, toggle: toggleMenu } = useNavDrawer();
  const { primaryNav, specialNav, ctaNav } = props;

  return (
    <>
      <Btn variant="link" text="primaryHeading" onClick={() => toggleMenu(true)}>
        <Menu size="xl" />
      </Btn>
      <Drawer
        className={styles.drawer}
        anchor="right"
        open={menuOpen}
        onClose={() => toggleMenu(false)}>
        <Flex justify="between" direction="column" className={styles.inner}>
          <Box>
            <Flex align="center" justify="end" className={styles.close}>
              <Btn variant="link" text="primaryHeading" onClick={() => toggleMenu(false)}>
                <Close size="xl" />
              </Btn>
            </Flex>
            {primaryNav?.items?.map((item) => {
              return <NavDrawerItem key={item.id} {...item} />;
            })}
            {<Box className={styles.border} colored={false} />}
            {specialNav?.items?.map((item) => {
              return <NavDrawerItem key={item.id} {...item} />;
            })}
          </Box>
          <Box cx={{ padding: '2' }}>
            {ctaNav?.items?.map((item) => {
              return (
                <Btn
                  key={item.id}
                  as="a"
                  type="link"
                  onClick={() => toggleMenu(false)}
                  href={item.href ?? ''}
                  variant="pill"
                  width="full"
                  colored
                  size="md">
                  <Txt
                    as="p"
                    variant="md"
                    uppercase
                    letterSpacing="10%"
                    style={{ fontSize: '0.75rem', fontWeight: '600' }}>
                    {item.title}
                  </Txt>
                </Btn>
              );
            })}
          </Box>
        </Flex>
      </Drawer>
    </>
  );
}
