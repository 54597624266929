import { create } from 'zustand';

export type NavBarResponsiveVariant = 'mobile' | 'desktop';

export type NavBarState = {
  responsiveVariant: NavBarResponsiveVariant;
  setResponsiveVariant: (responsiveVariant: NavBarResponsiveVariant) => void;

  fallbackHeight: number;
  height?: number | undefined;
  setHeight: (height: number | undefined) => void;
};

export const useNavbarState = create<NavBarState>()((set) => ({
  responsiveVariant: 'desktop',
  setResponsiveVariant: (responsiveVariant: NavBarResponsiveVariant) => set({ responsiveVariant }),

  fallbackHeight: 91,
  height: undefined,
  setHeight: (height?: number) => set(() => ({ height })),
}));

export const useIsDesktop = () => useNavbarState((s) => s.responsiveVariant === 'desktop');
