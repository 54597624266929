'use client';

import { NavMenuItem } from '@/lib/parse/navigation';
import Box from '@/polymorphics/Box';
import Btn from '@/polymorphics/Btn';
import Flex from '@/polymorphics/Flex';
import Txt from '@/polymorphics/Txt';
import { vars } from '@/style/vars.css';
import * as styles from './NavItem.css';
import { useSubItem } from './store/context';

export type NavItemProps = {
  item: NavMenuItem;
};

export default function NavItem({ item }: NavItemProps) {
  const { title, href, subItems } = item;
  const hasSubItems = subItems && subItems.length > 0;
  const state = useSubItem();

  return (
    <Box className={styles.root}>
      {!hasSubItems ? (
        <Btn href={href ?? ''} color="white" onClick={state.hide} variant="pill" size="sm">
          {/* Custom font size override */}
          <Txt as="p" variant="md" style={{ fontSize: vars.space[2] }}>
            {title}
          </Txt>
        </Btn>
      ) : (
        <Btn
          type="button"
          onClick={state.toggleShow}
          variant="pill"
          size="sm"
          color={state.show === true ? 'skyblue25' : 'white'}
          cx={{ cursor: 'pointer' }}
          iconType="chevron"
          iconSize="md">
          {/* Custom font size override */}
          <Txt as="p" variant="md" noMargin style={{ fontSize: vars.space[2] }}>
            {title}
          </Txt>
        </Btn>
      )}
      {state.show && hasSubItems && (
        <Flex direction="column" className={styles.subItem}>
          {subItems?.map((subItem) => {
            return (
              <Btn
                key={subItem.id}
                variant="link"
                href={subItem.href ?? ''}
                onClick={state.hide}
                cx={{ decoration: 'none', cursor: 'pointer' }}
                style={{ fontSize: vars.space[2] }}>
                <Txt as="p" variant="md" noMargin style={{ fontSize: vars.space[2] }}>
                  {subItem.title}
                </Txt>
              </Btn>
            );
          })}
        </Flex>
      )}
    </Box>
  );
}
