'use client';

import Logotype from '@/components/Assets/Logotype';
import NavItem from '@/components/NavItem';
import { NavMenu } from '@/lib/parse/navigation';
import Box from '@/polymorphics/Box';
import Btn from '@/polymorphics/Btn';
import Flex from '@/polymorphics/Flex';
import Txt from '@/polymorphics/Txt';
import { useBreakpointMin } from '@/style/utils/useBreakpoints';
import useScrollPosition from '@/style/utils/useScrollPosition';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useEffect, useRef, useState } from 'react';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';
import NavDrawer from '../NavDrawer';
import * as styles from './NavBar.css';
import NavShim from './NavShim';
import { useNavbarState } from './useNavbarState';

export type NavBar = {
  primaryNav: NavMenu | null;
  footerNav: NavMenu | null;
  extrasNav: NavMenu | null;
  specialNav: NavMenu | null;
  ctaNav: NavMenu | null;
};

type Size = {
  height?: number;
};

export default function NavBar(props: NavBar) {
  const ref = useRef<HTMLDivElement>(null);
  const { setHeight, fallbackHeight } = useNavbarState();
  const { primaryNav, specialNav, ctaNav } = props;
  const navBreakpoint = useBreakpointMin('nav');
  const [{ height }, setSize] = useState<Size>({
    height: undefined,
  });

  const pos = useScrollPosition();
  const onResize = useDebounceCallback(setSize, 200);

  useResizeObserver({
    ref,
    onResize,
  });

  useEffect(() => {
    setHeight(height);
  }, [setHeight, height]);

  return (
    <>
      <NavShim />
      <Flex
        colorTheme="light"
        colorSet="primary"
        as="nav"
        align="center"
        className={styles.root({ boxShadow: pos > 50 })}
        style={assignInlineVars({ [styles.navbarHeight]: `${height ?? fallbackHeight}px` })}>
        <Btn variant="link" href="/">
          <Logotype className={styles.logo} />
        </Btn>
        <Flex align="center" as="ul" className={styles.inner}>
          {navBreakpoint ? (
            <>
              {primaryNav?.items?.map((item) => {
                return <NavItem key={item.id} {...{ item }} />;
              })}
              {<Box className={styles.border} colored={false} />}
              {specialNav?.items?.map((item) => {
                return <NavItem key={item.id} {...{ item }} />;
              })}
              <Box cx={{ mL: '2' }}>
                {ctaNav?.items?.map((item) => {
                  return (
                    <Btn
                      key={item.id}
                      as="a"
                      type="link"
                      href={item.href ?? ''}
                      variant="pill"
                      colored
                      size="md"
                      focus>
                      <Txt
                        as="p"
                        uppercase
                        letterSpacing="10%"
                        cx={{ wrap: 'nowrap' }}
                        style={{ fontSize: '0.75rem', fontWeight: '400' }}>
                        {item.title}
                      </Txt>
                    </Btn>
                  );
                })}
              </Box>
            </>
          ) : (
            <NavDrawer {...props} />
          )}
        </Flex>
      </Flex>
    </>
  );
}
