import { gql } from '@/__generated__';

export const NEWS_CARD_FRAGMENT = gql(`
  fragment articleCard on article_Entry {
    ...entryBase
    ...entrySeo
    pageTitle
    summary: entrySummary
    featuredImage: entryImage {
      ...image
    }
    bannerImage {
      ...image
    }
  }
`);

export const ENTRIES_FRAGMENT = gql(`
  fragment entryCards on EntryInterface {
    ...articleCard
  }
`);
