import React, { HTMLAttributes } from 'react';

export default function Logotype(props: HTMLAttributes<SVGElement>) {
  return (
    <svg
      height="100%"
      fill="currentColor"
      viewBox="0 0 264 29"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g>
        <path d="M4.94089 14.3466L21.5642 0.426025H28.5866L13.8863 12.2961L29.4725 28.1878H22.839L10.2995 15.1986L4.93368 19.4946V28.195H0V0.426025H4.94089V14.3466Z"></path>
        <path d="M35.717 28.195H30.7761V0.426025H35.717V28.195Z"></path>
        <path d="M68.164 28.195H63.8426L44.9361 6.64985V28.1878H40.1897V0.426025H45.2818L63.4104 21.0397V0.426025H68.1568V28.195H68.164Z"></path>
        <path d="M140.405 28.195H135.464V0.426025H140.405V28.195Z"></path>
        <path d="M145.871 18.2888C148.997 22.9314 154.01 24.3249 158.18 24.3249C163.157 24.3249 166.744 22.6643 166.744 20.1877C166.744 17.2491 164.432 17.0181 156.329 16.0866C149.271 15.278 144.251 14.1155 144.251 8.70036C144.251 3.67509 149.185 0 156.906 0C163.035 0 168.588 2.31769 170.943 5.95668L167.392 8.93141C165.383 5.95668 161.53 4.28881 156.704 4.28881C151.223 4.28881 148.911 6.18051 148.911 8.19495C148.911 10.3971 150.683 10.9386 157.935 11.7906C166.348 12.7581 171.555 13.6823 171.555 19.6029C171.555 25.5235 165.577 28.6137 158.051 28.6137C151.223 28.6137 144.899 25.7906 142.313 21.574L145.864 18.2888H145.871Z"></path>
        <path d="M202.439 0.426025V28.195H197.499V16.0506H178.671V28.195H173.73V0.426025H178.671V11.7184H197.499V0.426025H202.439Z"></path>
        <path d="M233.273 0.426025V4.75816H211.363V11.7184H231.076V16.0506H211.363V23.8628H233.273V28.195H206.502V0.426025H233.273Z"></path>
        <path d="M241.779 28.195H236.838V0.426025H254.081C260.138 0.426025 264.301 3.32855 264.301 8.93144C264.301 13.2275 261.795 15.9712 257.819 17.0109L264.993 28.1878H259.361L252.612 17.4369H241.772V28.1878L241.779 28.195ZM253.815 13.1914C257.365 13.1914 259.447 11.6462 259.447 8.93866C259.447 6.23108 257.365 4.68595 253.815 4.68595H241.779V13.1914H253.815Z"></path>
        <path d="M132.655 4.75812V0.425993H106.38V12.296H102.606H85.0178V16.6282H98.2487V17.5596C98.0182 21.5018 92.8829 24.2527 87.373 24.2527C80.7756 24.2527 76.0724 20.231 76.0724 14.2744C76.0724 8.31769 80.8548 4.37545 87.6035 4.37545C91.6729 4.37545 94.8276 5.83393 96.837 8.54874H102.224C100.1 3.213 94.7051 0 87.5675 0C78.0386 0 70.9802 5.99278 70.9802 14.231C70.9802 22.4693 77.8442 28.6137 87.373 28.6137C92.2347 28.6137 96.3257 26.7942 97.9822 24.6282V28.1877H102.613V16.6282H106.388V28.1877H111.249V16.6282H130.458V12.296H111.249V4.75812H132.662H132.655Z"></path>
      </g>
    </svg>
  );
}
