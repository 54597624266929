import { rawColorValues } from '@/style/partials/palette.css';
import React from 'react';

export default function Logotype() {
  return (
    <svg fill={rawColorValues.white} viewBox="0 0 264 54" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M4.94089 14.3466L21.5642 0.426025H28.5866L13.8863 12.2961L29.4725 28.1878H22.839L10.2995 15.1986L4.93368 19.4946V28.195H0V0.426025H4.94089V14.3466Z"></path>
        <path d="M35.717 28.195H30.7761V0.426025H35.717V28.195Z"></path>
        <path d="M68.164 28.195H63.8426L44.9361 6.64985V28.1878H40.1897V0.426025H45.2818L63.4104 21.0397V0.426025H68.1568V28.195H68.164Z"></path>
        <path d="M140.405 28.195H135.464V0.426025H140.405V28.195Z"></path>
        <path d="M145.871 18.2888C148.997 22.9314 154.01 24.3249 158.18 24.3249C163.157 24.3249 166.744 22.6643 166.744 20.1877C166.744 17.2491 164.432 17.0181 156.329 16.0866C149.271 15.278 144.251 14.1155 144.251 8.70036C144.251 3.67509 149.185 0 156.906 0C163.035 0 168.588 2.31769 170.943 5.95668L167.392 8.93141C165.383 5.95668 161.53 4.28881 156.704 4.28881C151.223 4.28881 148.911 6.18051 148.911 8.19495C148.911 10.3971 150.683 10.9386 157.935 11.7906C166.348 12.7581 171.555 13.6823 171.555 19.6029C171.555 25.5235 165.577 28.6137 158.051 28.6137C151.223 28.6137 144.899 25.7906 142.313 21.574L145.864 18.2888H145.871Z"></path>
        <path d="M202.439 0.426025V28.195H197.499V16.0506H178.671V28.195H173.73V0.426025H178.671V11.7184H197.499V0.426025H202.439Z"></path>
        <path d="M233.273 0.426025V4.75816H211.363V11.7184H231.076V16.0506H211.363V23.8628H233.273V28.195H206.502V0.426025H233.273Z"></path>
        <path d="M241.779 28.195H236.838V0.426025H254.081C260.138 0.426025 264.301 3.32855 264.301 8.93144C264.301 13.2275 261.795 15.9712 257.819 17.0109L264.993 28.1878H259.361L252.612 17.4369H241.772V28.1878L241.779 28.195ZM253.815 13.1914C257.365 13.1914 259.447 11.6462 259.447 8.93866C259.447 6.23108 257.365 4.68595 253.815 4.68595H241.779V13.1914H253.815Z"></path>
        <path d="M132.655 4.75812V0.425993H106.38V12.296H102.606H85.0178V16.6282H98.2487V17.5596C98.0182 21.5018 92.8829 24.2527 87.373 24.2527C80.7756 24.2527 76.0724 20.231 76.0724 14.2744C76.0724 8.31769 80.8548 4.37545 87.6035 4.37545C91.6729 4.37545 94.8276 5.83393 96.837 8.54874H102.224C100.1 3.213 94.7051 0 87.5675 0C78.0386 0 70.9802 5.99278 70.9802 14.231C70.9802 22.4693 77.8442 28.6137 87.373 28.6137C92.2347 28.6137 96.3257 26.7942 97.9822 24.6282V28.1877H102.613V16.6282H106.388V28.1877H111.249V16.6282H130.458V12.296H111.249V4.75812H132.662H132.655Z"></path>
        <g>
          <path d="M10.2923 42.0793H5.76197V53.8266H4.53035V42.0793H0V40.989H10.2923V42.0793Z"></path>
          <path d="M17.9629 49.1553C17.9629 50.4983 17.6028 51.6752 16.8897 52.5344C16.1551 53.4802 15.0531 54.0001 13.7711 54.0001C12.489 54.0001 11.4159 53.4802 10.6524 52.5344C9.95378 51.6752 9.60086 50.4983 9.60086 49.1553C9.60086 47.8124 9.96098 46.6355 10.6524 45.7763C11.4159 44.8304 12.489 44.3105 13.7711 44.3105C15.0531 44.3105 16.1623 44.8304 16.8897 45.7763C17.6028 46.6355 17.9629 47.8124 17.9629 49.1553V49.1553ZM10.7749 49.1553C10.7749 51.4586 11.9705 52.982 13.7711 52.982C15.5717 52.982 16.7673 51.4658 16.7673 49.1553C16.7673 46.8448 15.5717 45.3286 13.7711 45.3286C11.9705 45.3286 10.7749 46.8448 10.7749 49.1553Z"></path>
          <path d="M20.5774 51.6967V45.5089H19.0289V44.4909H20.5774V41.7905H21.737V44.4909H23.9842V45.5089H21.737V51.5162C21.737 52.462 22.0971 52.8591 23.1127 52.8591C23.5232 52.8591 23.8617 52.8086 24.2363 52.7147V53.7544C23.7897 53.8772 23.3792 53.9133 22.8822 53.9133C21.2617 53.9133 20.5846 53.2129 20.5846 51.6967H20.5774Z"></path>
          <path d="M28.5649 54.0001C26.8003 54.0001 25.5687 52.9459 25.5687 51.3503C25.5687 49.7546 26.6779 48.7726 29.4364 48.2239L31.4531 47.8124V47.242C31.4531 46.0073 30.7401 45.3647 29.4004 45.3647C28.0608 45.3647 27.2757 45.9929 26.9732 47.1553L25.8496 46.816C26.2962 45.2997 27.6718 44.3105 29.4364 44.3105C31.4531 44.3105 32.6271 45.3503 32.6271 47.1914V52.1589C32.6271 52.7871 33.0881 53.0542 33.7867 52.8376V53.8412C32.375 54.0362 31.6116 53.6102 31.518 52.6066C30.9274 53.4441 29.7678 54.0001 28.5577 54.0001H28.5649ZM30.6537 52.3539C31.1866 51.9062 31.4531 51.3503 31.4531 50.7293V48.7654L29.775 49.1264C28.6154 49.3575 27.8159 49.6463 27.3837 49.9856C26.9732 50.3106 26.7571 50.751 26.7571 51.343C26.7571 52.3611 27.5206 53.0254 28.6802 53.0254C29.4436 53.0254 30.1063 52.8087 30.6393 52.3611L30.6537 52.3539Z"></path>
          <path d="M36.8406 40.9893V53.8268H35.6666V40.9893H36.8406V40.9893Z"></path>
          <path d="M43.7621 53.8268V40.9893H47.6515C49.5601 40.9893 51.1303 41.5958 52.2538 42.7582C53.3774 43.8846 53.9464 45.4947 53.9464 47.408C53.9464 49.3214 53.3774 50.9315 52.2538 52.0578C51.1303 53.2203 49.5601 53.8268 47.6515 53.8268H43.7621ZM44.9938 52.7366H47.6515C49.2 52.7366 50.4316 52.2528 51.3463 51.2853C52.2538 50.3178 52.722 49.0326 52.722 47.408C52.722 45.7835 52.261 44.4766 51.3463 43.5308C50.4388 42.5633 49.1856 42.0795 47.6371 42.0795H44.9938V52.7366V52.7366Z"></path>
          <path d="M56.7049 52.6643C55.9415 51.769 55.5453 50.6066 55.5453 49.1625C55.5453 47.8196 55.9055 46.6571 56.5969 45.7979C57.3604 44.816 58.4335 44.3105 59.7012 44.3105C60.8968 44.3105 61.8259 44.7221 62.5029 45.5452C63.1799 46.3683 63.5185 47.473 63.5185 48.8521V49.242H56.6689C56.6833 50.3683 56.9714 51.2636 57.526 51.9423C58.095 52.621 58.8441 52.9604 59.7588 52.9604C61.1128 52.9604 62.0924 52.2095 62.3805 50.9387L63.4536 51.2781C62.9567 52.9965 61.5954 53.9929 59.7588 53.9929C58.4912 53.9929 57.4756 53.5452 56.7121 52.6499L56.7049 52.6643ZM56.7193 48.3178H62.3229C62.2724 46.5127 61.2857 45.3503 59.7012 45.3503C58.1166 45.3503 56.993 46.4766 56.7193 48.3178Z"></path>
          <path d="M69.2228 53.8267H68.0992L64.5484 44.491H65.7945L68.6682 52.3393L71.542 44.491H72.7736L69.2228 53.8267V53.8267Z"></path>
          <path d="M74.5742 42.5777V40.9893H75.9499V42.5777H74.5742ZM75.8419 44.4911V53.8268H74.6679V44.4911H75.8419Z"></path>
          <path d="M80.1922 46.4042C79.6376 47.1046 79.3567 48.0143 79.3567 49.1551C79.3567 50.2959 79.6448 51.2273 80.1922 51.9277C80.7395 52.628 81.4958 52.9674 82.4033 52.9674C83.707 52.9674 84.6145 52.1804 84.917 50.823L85.9901 51.249C85.5652 52.9674 84.2039 53.9999 82.4033 53.9999C81.1213 53.9999 80.0121 53.5017 79.2486 52.5486C78.55 51.6894 78.1755 50.5125 78.1755 49.1479C78.1755 47.7833 78.55 46.6064 79.2486 45.7472C80.0121 44.8158 81.1213 44.2959 82.4033 44.2959C84.1895 44.2959 85.522 45.3356 85.9685 47.0324L84.917 47.48C84.6145 46.1226 83.707 45.3356 82.4033 45.3356C81.4958 45.3356 80.7468 45.6966 80.1922 46.3898V46.4042Z"></path>
          <path d="M88.6262 52.6643C87.8628 51.769 87.4666 50.6066 87.4666 49.1625C87.4666 47.8196 87.8268 46.6571 88.5182 45.7979C89.2889 44.816 90.3548 44.3105 91.6225 44.3105C92.8181 44.3105 93.7472 44.7221 94.4242 45.5452C95.1013 46.3683 95.4398 47.473 95.4398 48.8521V49.242H88.5902C88.6046 50.3683 88.8927 51.2636 89.4473 51.9423C90.0163 52.621 90.7654 52.9604 91.6801 52.9604C93.0341 52.9604 94.0137 52.2095 94.3018 50.9387L95.3749 51.2781C94.878 52.9965 93.5167 53.9929 91.6801 53.9929C90.4124 53.9929 89.3969 53.5452 88.6262 52.6499V52.6643ZM88.6478 48.3178H94.2514C94.2009 46.5127 93.2142 45.3503 91.6297 45.3503C90.0451 45.3503 88.9215 46.4766 88.6478 48.3178V48.3178Z"></path>
          <path d="M102.923 53.8268H101.677V40.9893H109.758V42.0795H102.93V46.7438H108.728V47.834H102.93V53.8268H102.923Z"></path>
          <path d="M115.463 44.4693V45.6462C115.232 45.5957 115.002 45.574 114.699 45.574C114 45.574 113.395 45.8628 112.913 46.4332C112.43 47.0036 112.178 47.7184 112.178 48.5776V53.8195H111.004V44.4838H112.178V46.2527C112.661 45.0903 113.59 44.3899 114.786 44.3899C115.016 44.3899 115.268 44.4043 115.463 44.4621V44.4693Z"></path>
          <path d="M117.616 52.6643C116.853 51.769 116.457 50.6066 116.457 49.1625C116.457 47.8196 116.817 46.6571 117.508 45.7979C118.279 44.816 119.345 44.3105 120.612 44.3105C121.808 44.3105 122.737 44.7221 123.414 45.5452C124.091 46.3683 124.43 47.473 124.43 48.8521V49.242H117.58C117.594 50.3683 117.883 51.2636 118.437 51.9423C119.006 52.621 119.755 52.9604 120.67 52.9604C122.024 52.9604 123.004 52.2095 123.292 50.9387L124.365 51.2781C123.868 52.9965 122.507 53.9929 120.67 53.9929C119.402 53.9929 118.387 53.5452 117.616 52.6499V52.6643ZM117.638 48.3178H123.241C123.191 46.5127 122.204 45.3503 120.62 45.3503C119.035 45.3503 117.911 46.4766 117.638 48.3178V48.3178Z"></path>
          <path d="M127.246 52.6643C126.482 51.769 126.086 50.6066 126.086 49.1625C126.086 47.8196 126.446 46.6571 127.138 45.7979C127.908 44.816 128.974 44.3105 130.242 44.3105C131.438 44.3105 132.367 44.7221 133.044 45.5452C133.721 46.3683 134.059 47.473 134.059 48.8521V49.242H127.21C127.224 50.3683 127.512 51.2636 128.067 51.9423C128.636 52.621 129.385 52.9604 130.3 52.9604C131.654 52.9604 132.633 52.2095 132.921 50.9387L133.995 51.2781C133.498 52.9965 132.136 53.9929 130.3 53.9929C129.032 53.9929 128.016 53.5452 127.246 52.6499V52.6643ZM127.267 48.3178H132.871C132.821 46.5127 131.834 45.3503 130.249 45.3503C128.665 45.3503 127.541 46.4766 127.267 48.3178V48.3178Z"></path>
          <path d="M136.695 52.4837C136.054 51.6245 135.716 50.4476 135.716 49.1552C135.716 47.8628 136.054 46.6859 136.695 45.8267C137.408 44.8231 138.482 44.3105 139.706 44.3105C141.06 44.3105 142.205 45.0108 142.774 46.1516V40.9819H143.948V53.8195H142.774V52.1588C142.205 53.2996 141.06 54 139.706 54C138.474 54 137.408 53.4801 136.695 52.4837ZM142.796 48.8014C142.796 47.8195 142.565 47.0108 142.083 46.426C141.564 45.7617 140.743 45.3718 139.908 45.3718C138.071 45.3718 136.897 46.8736 136.897 49.1624C136.897 51.4512 138.071 52.9675 139.908 52.9675C140.743 52.9675 141.528 52.592 142.083 51.9278C142.565 51.3357 142.796 50.5126 142.796 49.5668V48.8014Z"></path>
          <path d="M154.442 49.1553C154.442 50.4983 154.082 51.6752 153.369 52.5344C152.634 53.4802 151.532 54.0001 150.25 54.0001C148.968 54.0001 147.895 53.4802 147.132 52.5344C146.433 51.6752 146.08 50.4983 146.08 49.1553C146.08 47.8124 146.44 46.6355 147.132 45.7763C147.902 44.8304 148.968 44.3105 150.25 44.3105C151.532 44.3105 152.642 44.8304 153.369 45.7763C154.082 46.6355 154.442 47.8124 154.442 49.1553V49.1553ZM147.254 49.1553C147.254 51.4586 148.45 52.982 150.25 52.982C152.051 52.982 153.247 51.4658 153.247 49.1553C153.247 46.8448 152.051 45.3286 150.25 45.3286C148.45 45.3286 147.254 46.8448 147.254 49.1553Z"></path>
          <path d="M157.755 48.1373V53.8268H156.581V44.4911H157.755V45.9929C158.346 44.9387 159.376 44.3105 160.572 44.3105C161.854 44.3105 162.804 44.9748 163.229 46.1373C163.798 45.047 164.979 44.3105 166.262 44.3105C168.12 44.3105 169.279 45.6318 169.279 47.6896V53.8196H168.105V47.9568C168.105 46.3322 167.378 45.4152 166.017 45.4152C165.376 45.4152 164.713 45.7401 164.23 46.2528C163.748 46.751 163.517 47.4152 163.517 48.13V53.8196H162.343V47.9568C162.343 46.3322 161.616 45.4152 160.255 45.4152C159.614 45.4152 158.951 45.7401 158.468 46.2528C157.986 46.751 157.755 47.4152 157.755 48.13V48.1373Z"></path>
        </g>
      </g>
    </svg>
  );
}
