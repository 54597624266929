import { gql } from '@/__generated__';

export const ACCORDION_FRAGMENT = gql(`
  fragment accordionBlock on blocks_accordion_BlockType {
    id
    __typename
    enabled
    heading
    children {
      ...accordionItemBlock
    }
    linkCta {
      ...linkField
    }
  }
`);

export const ACCORDION_ITEM_FRAGMENT = gql(`
  fragment accordionItemBlock on blocks_accordionItem_BlockType {
    id
    __typename
    enabled
    heading
    content: htmlContentDefault
  }
`);

export const FEATURED_NEWS_FRAGMENT = gql(`
  fragment featuredNewsBlock on blocks_featuredNews_BlockType {
    id
    __typename
    enabled
    heading
    articleMultiple {
      ...articleCard
    }
  }
`);

export const HERO_STATEMENT_FRAGMENT = gql(`
  fragment heroStatementBlock on blocks_heroStatement_BlockType {
    id
    __typename
    enabled
    heading
    content: htmlContentDefault
    bgImage: imageSingle {
      ...image
    }
    colorTheme {
      label
    }
  }
`);

export const CTA_BLOCK_FRAGMENT = gql(`
  fragment ctaBlock on blocks_ctaBlock_BlockType {
    id
    __typename
    enabled
    ctaBlock {
      ...ctaEntry
    }
  }
`);

export const IMAGE_FRAGMENT = gql(`
  fragment imageBlock on blocks_image_BlockType {
    id
    __typename
    enabled
    imageSingle {
      ...image
    }
    imageWidth
    label
  }
`);

export const ICON_GRID_FRAGMENT = gql(`
  fragment iconGridBlock on blocks_iconGrid_BlockType {
    id
    __typename
    enabled
    heading
    content: htmlContentSimple
    children {
      ...iconCardBlock
    }

    # Customisation
    colorTheme {
      label
    }
  }
`);

export const ICON_CARD_FRAGMENT = gql(`
  fragment iconCardBlock on blocks_iconCard_BlockType {
    id
    __typename
    enabled
    icon
    heading
    content: htmlContentSimple
    linkCta {
      ...linkField
    }
  }
`);

export const IMAGE_AND_TEXT_FRAGMENT = gql(`
  fragment imageAndTextBlock on blocks_imageAndText_BlockType {
    id
    __typename
    enabled
    heading
    content: htmlContentSimple
    children {
      ...imageAndTextChildBlock
    } 
    colorTheme {
      label
    }
  }
`);

export const IMAGE_AND_TEXT_CHILD_FRAGMENT = gql(`
fragment imageAndTextChildBlock on blocks_imageAndTextChild_BlockType {
  id
  __typename
  enabled
  content: htmlContentDefault
    linkCta {
      ...linkField
    }
    imageSingle {
      ...image
    }
    imageType
    flipAlignment
}
`);

export const JOB_LISTINGS_FRAGMENT = gql(`
  fragment jobListingsBlock on blocks_jobListings_BlockType {
    id
    __typename
    enabled
    heading
    content: htmlContentSimple
    children {
      ...jobListingBlock
    }
  }
`);

export const JOB_LISTING_FRAGMENT = gql(`
  fragment jobListingBlock on blocks_jobListing_BlockType {
    id
    __typename
    enabled
    job: label
    content: htmlContentDefault
    linkCta {
      ...linkField
    }
  }
`);

export const LEADERSHIP_TEAM_FRAGMENT = gql(`
  fragment leadershipTeamBlock on blocks_leadershipTeam_BlockType {
    id
    __typename
    enabled
    profileMultiple {
      ...profileEntry
    }
  }
`);

export const RICH_TEXT_FRAGMENT = gql(`
  fragment richTextBlock on blocks_richText_BlockType {
    id
    __typename
    enabled
    content: htmlContentDefault
    linkCta {
      ...linkField
    }
    colorTheme {
      label
    }
  }
`);

export const STATS_FRAGMENT = gql(`
  fragment statsBlock on blocks_stats_BlockType {
    id
    __typename
    enabled
    heading
    content: htmlContentSimple
    linkCta {
      ...linkField
    }
    children {
      ...statBlock
    }
  }
`);

export const STAT_FRAGMENT = gql(`
  fragment statBlock on blocks_stat_BlockType {
    id
    __typename
    enabled
    number: label
    content: heading
    linkCta {
      ...linkField
    }
  }
`);

export const TESTIMONIAL_FRAGMENT = gql(`
  fragment testimonialBlock on blocks_testimonial_BlockType {
    id
    __typename
    enabled
    quote: heading
    firstName
    lastName
    jobPosition: label
    colorTheme {
      label
    }
  }
`);

export const TABLE_FRAGMENT = gql(`
  fragment tableBlock on blocks_table_BlockType {
    id
    __typename
    enabled
    heading
    content: htmlContentSimple
    label
    table {
      rows
      table
      columns {
        align
        heading
        width
      }
    }
    colorTheme {
      label
    }
  }
`);

export const TYPEFORM_FRAGMENT = gql(`
  fragment typeformBlock on blocks_typeform_BlockType {
    id
    __typename
    enabled
    typeformUrl: label
  }
`);

export const VIDEO_FRAGMENT = gql(`
  fragment videoBlock on blocks_video_BlockType {
    id
    __typename
    enabled
    heading
    videoUrl: websiteUrl
    colorTheme {
      label
    }
  }
`);

export const RELATED_ARTICLES_FRAGMENT = gql(`
  fragment curatedArticlesBlock on blocks_curatedArticles_BlockType {
    id
    __typename
    enabled
    heading
    articleMultiple {
      ...articleCard
    }
  }
`);

export const QUOTE_FRAGMENT = gql(`
  fragment quoteBlock on blocks_quote_BlockType {
    id
    __typename
    content: heading
    author: label
    lightswitch
  }
`);

export const MEDIA_GALLERY_FRAGMENT = gql(`
  fragment mediaGalleryBlock on blocks_mediaGallery_BlockType {
    id
    __typename
    heading
    imageMultiple {
      ...image
    }
  }
`);

export const PDF_DOWNLOAD_FRAGMENT = gql(`
  fragment pdfDownloadBlock on blocks_pdfDownload_BlockType {
    id
    __typename
    documentMultiple {
      ...document
    }
  }
`);

// -----------------------------------------------------------------------------

export const BLOCKS_FRAGMENT = gql(`
  fragment blocks on blocks_NeoField {
    ...accordionBlock
    ...accordionItemBlock
    ...featuredNewsBlock
    ...heroStatementBlock
    ...ctaBlock
    ...imageBlock
    ...mediaGalleryBlock
    ...iconGridBlock
    ...iconCardBlock
    ...imageAndTextBlock
    ...jobListingsBlock
    ...jobListingBlock
    ...leadershipTeamBlock
    ...richTextBlock
    ...statsBlock
    ...statBlock
    ...testimonialBlock
    ...tableBlock
    ...typeformBlock
    ...videoBlock
    ...curatedArticlesBlock
    ...quoteBlock
    ...pdfDownloadBlock
  }
`);
