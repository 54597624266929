import { gql } from '@/__generated__';

export const LINK_FIELD_URL_FRAGMENT = gql(`
  fragment linkFieldUrl on linkCta_ExternalLink_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_ENTRY_FRAGMENT = gql(`
  fragment linkFieldEntry on linkCta_InternalLink_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_EMAIL_FRAGMENT = gql(`
  fragment linkFieldEmail on linkCta_Email_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_PHONE_FRAGMENT = gql(`
  fragment linkFieldPhone on linkCta_Phone_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FRAGMENT = gql(`
  fragment linkField on HyperLinkInterface {
    ...linkFieldUrl
    ...linkFieldEntry
    ...linkFieldEmail
    ...linkFieldPhone
  }
`);
