'use client';

import { NavMenuItem } from '@/lib/parse/navigation';
import Box from '@/polymorphics/Box';
import Btn from '@/polymorphics/Btn';
import Flex from '@/polymorphics/Flex';
import Txt from '@/polymorphics/Txt';
import * as styles from './NavDrawerItem.css';
import { useSubItem } from './store/context';
import { useNavDrawer } from '../NavDrawer/useNavDrawer';

export default function NavDrawerItem(props: NavMenuItem) {
  const { title, href, subItems } = props;
  const hasSubItems = subItems && subItems.length > 0;
  const { toggle: toggleMenu } = useNavDrawer();
  const state = useSubItem();

  const handleHide = () => {
    state.hide();
    toggleMenu(false);
  };

  const handleToggle = () => {
    state.toggleShow();
  };

  return (
    <Box colored={false} className={styles.root({ active: state.show && hasSubItems })}>
      {!hasSubItems && (
        <Btn href={href ?? ''} onClick={handleHide} variant="link" size="md" decoration="none">
          <Txt as="p" variant="h6" font="body">
            {title}
          </Txt>
        </Btn>
      )}
      {hasSubItems && (
        <Btn
          type="button"
          onClick={handleToggle}
          variant="link"
          size="md"
          decoration="none"
          cx={{ cursor: 'pointer' }}
          iconType="chevron"
          iconSize="md">
          <Txt as="span" variant="h6" font="body">
            {title}
          </Txt>
        </Btn>
      )}
      {state.show && hasSubItems && (
        <Flex colored={false} direction="column" className={styles.subItem}>
          {props.subItems?.map((subItem) => {
            return (
              <Btn
                key={subItem.id}
                href={subItem.href ?? ''}
                onClick={handleHide}
                variant="link"
                size="md"
                decoration="none">
                <Txt as="p" variant="md" font="body">
                  {subItem.title}
                </Txt>
              </Btn>
            );
          })}
        </Flex>
      )}
    </Box>
  );
}
