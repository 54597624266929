import { gql } from '@/__generated__';

export const ENTRY_BASE_FRAGMENT = gql(`
  fragment entryBase on EntryInterface {
    __typename
    typeHandle
    searchScore
    id
    uri
    url
    slug
    sectionHandle
    parent {
      title
    }
    title
    postDate @formatDateTime(format: "j M, Y")
  }
`);

export const PAGE_STANDARD_ENTRY_FRAGMENT = gql(`
  fragment pageStandardEntry on standard_Entry {
    ...entryBase
    ...entrySeo
    pageTitle
    summary: entrySummary
    featuredImage: entryImage {
      ...image
    }
    headerLogo: imageSingle {
      ...image
    }
    blocks {
      ...blocks
    }
    headerType
    colorTheme {
      label
    }
  }
`);

export const PAGE_OVERVIEW_ENTRY_FRAGMENT = gql(`
  fragment pageOverviewEntry on overview_Entry {
    ...entryBase
    ...entrySeo
    pageTitle
    summary: entrySummary
    featuredImage: entryImage {
      ...image
    }
    blocks {
      ...blocks
    }
    headerLogo: imageSingle {
      ...image
    }
    headerType
    colorTheme {
      label
    }
  }
`);

export const NEWS_INDEX_ENTRY_FRAGMENT = gql(`
  fragment articleIndexEntry on articleIndex_Entry {
    ...entryBase
    ...entrySeo
    pageTitle
    summary: entrySummary
    featuredImage: entryImage {
      ...image
    }
    blocks {
      ...blocks
    }
  }
`);

export const NEWS_ENTRY_FRAGMENT = gql(`
  fragment articleEntry on article_Entry {
    ...articleCard
    blocks {
      ...blocks
    }
  }
`);

export const CTA_ENTRY_FRAGMENT = gql(`
  fragment ctaEntry on ctaBlock_Entry {
    ...entryBase
    imageSingle {
      ...image
    }
    heading
    content: htmlContentSimple
    linkCta {
      ...linkField
    }
    colorTheme {
      label
    }
  }
`);

export const PROFILES_ENTRY_FRAGMENT = gql(`
  fragment profileEntry on profile_Entry {
    ...entryBase
    firstName
    lastName
    imageSingle {
      ...image
    }
    jobPosition: label
    description: htmlContentSimple
    linkedinUrl: websiteUrl
  }
`);

export const HOME_ENTRY_FRAGMENT = gql(`
  fragment homeEntry on home_Entry {
    ...entryBase
    ...entrySeo
    pageTitle
    summary: entrySummary
    featuredImage: entryImage {
      ...image
    }
    blocks {
      ...blocks
    }
    headerType
    colorTheme {
      label
    }
  }
`);

export const PARTNERS = gql(`
  fragment partnerMultiple on partnerMultiple_Entry {
      ...entryBase
      linkCta {
        ...linkField
      }
      logo {
        ...image
      }
  }
`);

export const ENTRIES_FRAGMENT = gql(`
  fragment entries on EntryInterface {
    ...pageStandardEntry
    ...pageOverviewEntry
    ...articleIndexEntry
    ...articleEntry
    ...ctaEntry
    ...profileEntry
    ...homeEntry
    ...partnerMultiple
  }
`);
